import React, { Fragment } from "react"
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Spinner,
    Row,
    Col,
    Label,
    FormGroup
} from "reactstrap"
import {
    AlertOctagon,
} from "react-feather"
import { connect } from "react-redux"
import { getAllBankList, addBank } from "../../../redux/actions/bank/bankAction"
import "../../../assets/scss/pages/data-list.scss"
import { toast, ToastContainer, Zoom } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "../../../assets/scss/plugins/extensions/toastr.scss"
import {
    Edit,
    Trash,
    ChevronDown,
    ChevronUp,
    Plus,
    Check,
    ChevronLeft,
    ChevronRight,
    Eye
} from "react-feather"

class BankAdd extends React.Component {

    state = {
        modal: false,
        accountName: "",
        accountNumber: "",
        bankName: ""
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }))
        // this.props.handleEventViewClose()
    }


    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        var date = [year, month, day].join('-');
        return date
    }

    componentDidUpdate(prevProps) {

    }

    componentDidMount() {

    }

    handleSubmit(value) {
        let data = {
            "accountName": value.accountName,
            "accountNumber": value.accountNumber,
            "bankName": value.bankName
        }
        this.props.addBank(data).then(()=>{
            if(this.props.bankList && this.props.bankList.bank && this.props.bankList.bank.bankAddError){
                this.props.notifyError()
            }
            else {
                this.props.notifyAdd()
                this.props.refreshData()
                this.props.handleBankAddModal()
            }
            console.log(this.props.bankList,'added bank list')
        })
    }


    render() {
        let {
            accountNumber,
            accountName,
            bankName
        } = this.state

        return (
            <Fragment>
                <Modal
                    isOpen={this.props.bankAdd}
                    toggle={() => this.props.handleBankAddModal()}
                    className="modal-dialog-centered"
                >
                    <ModalHeader toggle={() => this.props.handleBankAddModal()} className="bg-primary">
                        Add Bank Details
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col sm="12">
                                <Label style={{ fontWeight: "bolder" }} for="remark">Bank Name :</Label>
                                <FormGroup className="position-relative">
                                    <Input
                                       type="text"
                                       name="bankName"
                                       value={bankName}
                                       onChange={e => this.setState({ bankName: e.target.value })}
                                       id="bankName"
                                       placeholder="Bank Name">
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <Label style={{ fontWeight: "bolder" }} for="remark">Account Name :</Label>
                                <FormGroup className="position-relative">
                                    <Input
                                       type="text"
                                       name="accountName"
                                       value={accountName}
                                       onChange={e => this.setState({ accountName: e.target.value })}
                                       id="accountName"
                                       placeholder="Account Name">
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col sm="12">
                                <Label style={{ fontWeight: "bolder" }} for="remark">Account Number :</Label>
                                <FormGroup className="position-relative">
                                    <Input
                                       type="text"
                                       name="accountNumber"
                                       value={accountNumber}
                                       onChange={e => this.setState({ accountNumber: e.target.value })}
                                       id="accountNumber"
                                       placeholder="Account Number">
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => this.props.handleBankAddModal()}>
                            Cancel
                        </Button>
                        <Button color="primary"
                            disabled = {accountName == '' || accountNumber == '' || bankName == '' || this.props.bankList.bank.isbankaddLoading}
                            onClick={() => this.handleSubmit(this.state)}>
                            Submit
                        </Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        bankList: state.bankList
    }
}

export default connect(mapStateToProps, {
    getAllBankList,
    addBank
})(BankAdd)