import React, { Fragment } from "react"
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Spinner,
    Label,
    FormGroup
} from "reactstrap"
import classnames from "classnames"
import {
    AlertOctagon,
} from "react-feather"
import { connect } from "react-redux"
import { getTotalProfit } from "../../../redux/actions/workpayment/paymentAction"
import StatisticsCard from "../../../components/@vuexy/statisticsCard/StatisticsCard"
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import "../../../assets/scss/plugins/forms/flatpickr/flatpickr.scss"

class Profit extends React.Component {

    state = {
        modal: false,
        startDate: new Date(),
        endDate: new Date(),
        profit: {
            "credit": "0",
            "debit": "0",
            "endDate": "",
            "profit": "0",
            "startDate": ""
        }
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }))
        // this.props.handleEventViewClose()
    }


    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        var date = [year, month, day].join('-');
        return date
    }

    componentDidUpdate(prevProps) {

    }

    componentDidMount() {

    }

    handleSearch() {
        var startDate = this.formatDate(this.state.startDate)
        var endDate = this.formatDate(this.state.endDate)
        this.props.getTotalProfit(startDate, endDate).then(() => {
            console.log(this.props.paymentList, 'payment')
            if (this.props.paymentList && this.props.paymentList.payment && this.props.paymentList.payment.totalprofitList) {
                this.setState({
                    profit: this.props.paymentList.payment.totalprofitList,
                })
            }
        })
    }


    render() {
        let { profit, startDate, endDate } = this.state
        const headerStyle = {
            "borderBottom": "none",
            "padding": "10px 10px 0px 10px",
            "backgroundColor": "#d3cbcb"
        }
        const bodyStyle = {
            // "backgroundColor": "lavender",
            // "fontWeight": "500"
        }
        let cardStyle = {
            "marginBottom": "0px",
            // "width": "250px"
        }
        let cardBodyStyle = {
            "padding": "10px"
        }
        let red = {
            "color": "red"
        }
        let green = {
            "color": "green"
        }
        let orenge = {
            "color": "orange"
        }
        let redBorder = {
            "border": "2px solid red",
            "borderRadius": "5px"
        }
        let greenBorder = {
            "border": "2px solid green",
            "borderRadius": "5px"
        }
        let orengeBorder = {
            "border": "2px solid orange",
            "borderRadius": "5px"
        }
        let firstcard = {
            "padding": "3px 10px 0px 10px",
            "border": "solid 2px #7c71f1",
            "borderRadius": "5px"
        }
        let disablebutton = {
            marginTop: "25px", cursor: "not-allowed"
        }
        let enablebutton = {
            marginTop: "25px"
        }
        return (
            <Fragment>
                <Modal
                    isOpen={this.props.show}
                    toggle={() => this.props.handleProfitClose()}
                    className="modal-dialog-centered"
                >
                    <ModalHeader toggle={() => this.props.handleProfitClose()} className="bg-primary">
                        Profit Details
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <Card>
                                <CardHeader style={headerStyle}>
                                    <CardTitle>
                                        <Row>
                                            <Col md="4">
                                                <Label for="startDate"><b>Start Date :</b></Label>
                                                <FormGroup className="position-relative">
                                                    <Flatpickr
                                                        className="form-control"
                                                        value={startDate}
                                                        options={{ dateFormat: "d-m-Y" }}
                                                        onChange={date => {
                                                            this.setState({ startDate: date });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <Label for="endDate"><b>End Date :</b></Label>
                                                <FormGroup className="position-relative">
                                                    <Flatpickr
                                                        className="form-control"
                                                        value={endDate}
                                                        options={{ dateFormat: "d-m-Y" }}
                                                        onChange={date => {
                                                            this.setState({ endDate: date });
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <Button style={(startDate === "" || endDate === "") ? disablebutton : enablebutton}
                                                    color="primary"
                                                    disabled={startDate === "" || endDate === ""}
                                                    onClick={() => this.handleSearch()}>
                                                    Search
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                </CardHeader>
                                {this.props.paymentList.payment.istotalprofitLoading ?
                                    <div className="text-center">
                                        <Spinner color="primary" size="lg" />
                                    </div>
                                    :
                                    <CardBody className="text-center" style={bodyStyle}>
                                        <Row>
                                            <Col md="12" className="mb-2">
                                                <StatisticsCard
                                                    cardStyle={cardStyle}
                                                    cardBodyStyle={cardBodyStyle}
                                                    padding={false}
                                                    statStyle={orenge}
                                                    borderColor={orengeBorder}
                                                    hideChart
                                                    iconRight
                                                    iconBg="warning"
                                                    icon={<AlertOctagon className="warning" size={22} />}
                                                    stat={profit.profit.toLocaleString()}
                                                    statTitle="Total Profit"
                                                />
                                            </Col>
                                            <Col md="12" className="mb-2">
                                                <StatisticsCard
                                                    cardStyle={cardStyle}
                                                    cardBodyStyle={cardBodyStyle}
                                                    padding={false}
                                                    statStyle={red}
                                                    borderColor={redBorder}
                                                    hideChart
                                                    iconRight
                                                    iconBg="danger"
                                                    icon={<AlertOctagon className="danger" size={22} />}
                                                    stat={profit.debit.toLocaleString()}
                                                    statTitle="Total Debit"
                                                />
                                            </Col>
                                            <Col md="12" className="mb-2">
                                                <StatisticsCard
                                                    cardStyle={cardStyle}
                                                    cardBodyStyle={cardBodyStyle}
                                                    padding={false}
                                                    statStyle={parseInt(profit.credit) < 0 ? red : green}
                                                    borderColor={parseInt(profit.credit) < 0 ? redBorder : greenBorder}
                                                    hideChart
                                                    iconRight
                                                    iconBg={parseInt(profit.credit) < 0 ? "danger" : "success"}
                                                    icon={<AlertOctagon className={parseInt(profit.credit) < 0 ? "danger" : "success"} size={22} />}
                                                    stat={profit.credit.toLocaleString()}
                                                    statTitle="Total Credit"
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                }
                            </Card>
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        paymentList: state.paymentList
    }
}

export default connect(mapStateToProps, {
    getTotalProfit
})(Profit)