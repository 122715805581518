export const workschedule = (state = { userRole: "Admin" }, action) => {
    switch (action.type) { 

      // LISTING  
      case "GET_ASSIGNED_LIST_REQUEST": {
        return { ...state, isassignlistLoading: true }
      }
      case "GET_ASSIGNED_LIST_SUCCESS": {
        return {...state , values: action.payload, assignList:action.payload, isassignlistLoading : false }
      }
      case "GET_ASSIGNED_LIST_ERROR" : {
        return {...state, isassignlistLoading : false, assignListError :action.payload }
      }

      case "GET_ALL_STAFF_LIST_REQUEST": {
        return { ...state, isassignlistLoading: true }
      }
      case "GET_ALL_STAFF__LIST_SUCCESS": {
        return {...state , values: action.payload, staffList:action.payload, isassignlistLoading : false }
      }
      case "GET_ALL_STAFF__LIST_ERROR" : {
        return {...state, isassignlistLoading : false, staffListError :action.payload }
      }

      case "ADD_ASSIGNED_DATA_REQUEST": {
        return { ...state, isaddassignLoading: true }
      }
      case "ADD_ASSIGNED_DATA_SUCCESS": {
        return {...state , values: action.payload, assignedData:action.payload, assignedDataError:null, isaddassignLoading : false }
      }
      case "ADD_ASSIGNED_DATA_ERROR" : {
        return {...state, isaddassignLoading : false, assignedDataError :action.payload, assignedData:null }
      }


      default: {
        return state
      }
    }
  }
  