export const staffworkhistory = (state = { userRole: "Admin" }, action) => {
    switch (action.type) { 

      // LISTING  
      case "GET_ALL_STAFF_LIST_REQUEST": {
        return { ...state, isallstafflistLoading: true }
      }
      case "GET_ALL_STAFF_LIST_SUCCESS": {
        return {...state , values: action.payload, 
          allStaffList:action.payload, 
          allStaffListError: null,
          isallstafflistLoading : false }
      }
      case "GET_ALL_STAFF_LIST_ERROR" : {
        return {...state, isallstafflistLoading : false, 
          allStaffListError :action.payload ,
          allStaffList: null
        }
      }

      case "GET_STAFF_WORK_HISTORY_REQUEST": {
        return { ...state, isstaffworkhistoryLoading: true }
      }
      case "GET_STAFF_WORK_HISTORY_SUCCESS": {
        return {...state , values: action.payload, 
          staffWorkHistoryList:action.payload, 
          staffWorkHistoryListError: null,
          isstaffworkhistoryLoading : false }
      }
      case "GET_STAFF_WORK_HISTORY_ERROR" : {
        return {...state, isstaffworkhistoryLoading : false, 
          staffWorkHistoryListError :action.payload,
          staffWorkHistoryList: null
        }
      }


      default: {
        return state
      }
    }
  }
  