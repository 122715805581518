export const payment = (state = { userRole: "Admin" }, action) => {
  switch (action.type) {

    // LISTING  
    case "GET_ALL_WORK_LIST_REQUEST": {
      return { ...state, isallworklistLoading: true }
    }
    case "GET_ALL_WORK_LIST_SUCCESS": {
      return {
        ...state, values: action.payload,
        allWorkList: action.payload,
        allWorkListError: null,
        isallworklistLoading: false
      }
    }
    case "GET_ALL_WORK_LIST_ERROR": {
      return {
        ...state, isallworklistLoading: false,
        allWorkListError: action.payload,
        allWorkList: null,
      }
    }

    // PROFIT  
    case "GET_TOTAL_PROFIT_REQUEST": {
      return { ...state, istotalprofitLoading: true }
    }
    case "GET_TOTAL_PROFIT_SUCCESS": {
      return {
        ...state, values: action.payload,
        totalprofitList: action.payload,
        totalprofitListError: null,
        istotalprofitLoading: false
      }
    }
    case "GET_TOTAL_PROFIT_ERROR": {
      return {
        ...state, istotalprofitLoading: false,
        totalprofitListError: action.payload,
        totalprofitList: null,
      }
    }

    case "GET_WORK_PAYMENT_REQUEST": {
      return { ...state, isworkpaymentLoading: true }
    }
    case "GET_WORK_PAYMENT_SUCCESS": {
      return {
        ...state, values: action.payload,
        paymentList: action.payload,
        paymentListError: null,
        isworkpaymentLoading: false
      }
    }
    case "GET_WORK_PAYMENT_ERROR": {
      return {
        ...state, isworkpaymentLoading: false,
        paymentListError: action.payload,
        paymentList: null
      }
    }

    // ADD
    case "ADD_PAYMENT_REQUEST": {
      return { ...state, ispaymentaddLoading: true }
    }
    case "ADD_PAYMENT_SUCCESS": {
      return { ...state, values: action.payload, paymentAdd: action.payload, ispaymentaddLoading: false }
    }
    case "ADD_PAYMENT_ERROR": {
      return { ...state, ispaymentaddLoading: false, paymentAddError: action.payload }
    }

    // UPDATE
    case "UPDATE_PAYMENT_REQUEST": {
      return { ...state, ispaymentupdateLoading: true }
    }
    case "UPDATE_PAYMENT_SUCCESS": {
      return { ...state, values: action.payload, paymentUpdate: action.payload, ispaymentupdateLoading: false }
    }
    case "UPDATE_PAYMENT_ERROR": {
      return { ...state, ispaymentupdateLoading: false, paymentUpdateError: action.payload }
    }


    default: {
      return state
    }
  }
}
