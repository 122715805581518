export const staff = (state = { userRole: "Admin" }, action) => {
    switch (action.type) { 

      // LISTING  
      case "GET_STAFF_LIST_REQUEST": {
        return { ...state, isstafflistLoading: true }
      }
      case "GET_STAFF_LIST_SUCCESS": {
        return {...state , values: action.payload, staffList:action.payload, isstafflistLoading : false }
      }
      case "GET_STAFF_LIST_ERROR" : {
        return {...state, isstafflistLoading : false, staffListError :action.payload }
      }

      // ADD
      case "ADD_STAFF_REQUEST": {
        return { ...state, isstaffaddLoading: true }
      }
      case "ADD_STAFF_SUCCESS": {
        return {...state , values: action.payload, staffAdd:action.payload, isstaffaddLoading : false }
      }
      case "ADD_STAFF_ERROR" : {
        return {...state, isstaffaddLoading : false, staffAddError :action.payload }
      }

      // UPDATE
      case "UPDATE_STAFF_REQUEST": {
        return { ...state, isstaffupdateLoading: true }
      }
      case "UPDATE_STAFF_SUCCESS": {
        return {...state , values: action.payload, staffUpdate:action.payload, isstaffupdateLoading : false }
      }
      case "UPDATE_STAFF_ERROR" : {
        return {...state, isstaffupdateLoading : false, staffUpdateError :action.payload }
      }

      // DELETE
      case "DELETE_STAFF_REQUEST": {
        return { ...state, isstaffdeleteLoading: true }
      }
      case "DELETE_STAFF_SUCCESS": {
        return {...state , values: action.payload, staffDelete:action.payload, isstaffdeleteLoading : false }
      }
      case "DELETE_STAFF_ERROR" : {
        return {...state, isstaffdeleteLoading : false, staffDeleteError :action.payload }
      }

      default: {
        return state
      }
    }
  }
  