import React, { Fragment } from "react"
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Progress,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    Input,
    Spinner,
    Collapse,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Label,
    FormGroup
} from "reactstrap"
import {
    AlertOctagon,
} from "react-feather"
import { connect } from "react-redux"
import { getAllBankList } from "../../../redux/actions/bank/bankAction"
import StatisticsCard from "../../../components/@vuexy/statisticsCard/StatisticsCard"
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import "../../../assets/scss/plugins/forms/flatpickr/flatpickr.scss"
import DataTable from "react-data-table-component"
import classnames from "classnames"
import "../../../assets/scss/pages/data-list.scss"
import { toast, ToastContainer, Zoom } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "../../../assets/scss/plugins/extensions/toastr.scss"
import {
    Edit,
    Trash,
    ChevronDown,
    ChevronUp,
    Plus,
    Check,
    ChevronLeft,
    ChevronRight,
    Eye
} from "react-feather"
import BankAdd from './bankAdd'
import BankUpdate from './bankUpdate'

const chipColors = {
    "on hold": "warning",
    delivered: "success",
    pending: "primary",
    canceled: "danger"
}

const selectedStyle = {
    rows: {
        selectedHighlighStyle: {
            backgroundColor: "rgba(115,103,240,.05)",
            color: "#7367F0 !important",
            boxShadow: "0 0 1px 0 #7367F0 !important",
            "&:hover": {
                transform: "translateY(0px) !important"
            }
        }
    }
}
const conditionalRowStyles = [
    {
        when: row => row,
        style: {
            backgroundColor: '#c2c6dc66',
            color: "black !important",
            fontSize: "15px !important"
        },
    }
];

const ActionsComponent = props => {
    return (
        <div className="data-list-action">
            <Edit
                className="cursor-pointer mr-1"
                size={20}
                onClick={() => {
                    return props.currentData(props.row)
                }}
            />
        </div>
    )
}

const CustomHeader = props => {
    return (
        <div style={{float:'right'}} className="data-list-header d-flex justify-content-between flex-wrap">
            <div className="actions-right d-flex flex-wrap">
                <Button
                    className="add-new-btn"
                    color="primary"
                    onClick={() => props.handleBankAddModal()}
                    outline>
                    <Plus size={15} />
                    <span className="align-middle">Add New</span>
                </Button>
            </div>
        </div>
    )
}

const notifyUpdate = () => toast.info("Bank Details Updated Successfully", { transition: Zoom })
const notifyAdd = () => toast.success("Bank Details Added Successfully", { transition: Zoom })
const notifyWarning = () => toast.warning("Please fill all fields !", { transition: Zoom })
const notifyError = () => toast.warning("Something went wrong.Please try again..", { transition: Zoom })
const notifyValid = () => toast.warning("Please enter valid datas..", { transition: Zoom })

class Bank extends React.Component {

    state = {
        modal: false,
        bankAdd : false,
        editData : '',
        bankUpdate : false,
        data: [],
        totalPages: 0,
        currentPage: 0,
        columns: [
            
            {
                name: "Bank Id",
                selector: "bankId",
                sortable: true,
                minWidth: "180px",
                maxWidth: "10%",
                cell: row => (<span>{row.accountId}</span>),
            },
            {
                name: "Account Name",
                selector: "accountName",
                sortable: true,
                minWidth: "150px",
                maxWidth: "20%",
                cell: row => (<span>{row.accountName}</span>),
            },
            {
                name: "Account Number",
                selector: "accountNumber",
                sortable: true,
                minWidth: "200px",
                maxWidth: "20%",
                cell: row => (<span>{row.accountNumber}</span>),
            },
            {
                name: "Bank Name",
                selector: "bankName",
                sortable: true,
                minWidth: "200px",
                maxWidth: "20%",
                cell: row => (<span>{row.bankName}</span>),
            },
            {
                name: "Amount",
                selector: "amount",
                sortable: true,
                minWidth: "130px",
                maxWidth: "13%",
                cell: row => (<span>{row.amount}</span>),
            },
            // {
            //     name: "Actions",
            //     sortable: true,
            //     minWidth: "80px",
            //     maxWidth: "8%",
            //     cell: row => (
            //         <ActionsComponent
            //             row={row}
            //             currentData={this.handleCurrentData}
            //         />
            //     )
            // }
        ],
        allData: [],
        value: "",
        currentData: null,
        addNew: "",
        startDate: new Date(),
        endDate: new Date(),
    }
    child = React.createRef();
    view = React.createRef();
    thumbView = this.props.thumbView

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }))
        // this.props.handleEventViewClose()
    }

    handleBankAddModal = () => {
        this.setState(prevState => ({
            bankAdd: !prevState.bankAdd
        }))
    }

    handleCurrentData = (data) => {
        this.setState({editData : data})
        this.setState(prevState => ({
            bankUpdate: !prevState.bankUpdate
        }))
    }



    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        var date = [year, month, day].join('-');
        return date
    }

    componentDidUpdate(prevProps) {

    }

    componentDidMount() {
        this.GetAllData()
    }

    GetAllData(){
        this.props.getAllBankList().then(() => {
            if (this.props.bankList && this.props.bankList.bank && this.props.bankList.bank.allBankList.bankAccountViewList) {
                let temp_arr = this.props.bankList.bank.allBankList.bankAccountViewList
                let totalAmount = this.props.bankList.bank.total
                console.log("totalAmount ",totalAmount)
                var new_arr = []
                for(let i=0;i<temp_arr.length;i++){
                    temp_arr[i].id = i+1
                    new_arr.push(temp_arr[i])
                }
                this.setState({
                    data: new_arr,
                    allData: new_arr,
                })
            }
        })
    }

    refreshData () {
        this.GetAllData()
    }

    handleSearch() {

    }


    render() {
        let {
            columns,
            data,
            allData,
            value,
        } = this.state

        return (
            <Fragment>
                <Modal
                    isOpen={this.props.show}
                    toggle={() => this.props.handleBankClose()}
                    className="modal-xl"
                >
                    <ModalHeader toggle={() => this.props.handleBankClose()} className="bg-primary">
                        Bank Details
                    </ModalHeader>
                    <ModalBody style={{minHeight: '300px'}}>
                        {this.props.bankList.bank.isallbankistLoading ?
                            <div className="text-center">
                                <Spinner color="primary" size="lg" />
                            </div>
                            :
                            <>
                                <div
                                    className={`data-list ${this.props.thumbView ? "thumb-view" : "list-view"
                                        }`}>
                                    <DataTable
                                        columns={columns}
                                        data={value.length ? allData : data}
                                        noHeader
                                        subHeader
                                        responsive
                                        pointerOnHover
                                        customStyles={selectedStyle}
                                        conditionalRowStyles={conditionalRowStyles}
                                        subHeaderComponent={
                                            <CustomHeader
                                                handleBankAddModal = {this.handleBankAddModal}
                                            // handleSidebar={this.handleSidebar}
                                            />
                                        }
                                    />

                                </div>
                            </>
                        }
                        {this.state.bankAdd &&
                            <BankAdd
                                bankAdd = {this.state.bankAdd}
                                handleBankAddModal = {this.handleBankAddModal}
                                notifyAdd = {notifyAdd}
                                notifyError = {notifyError}
                                refreshData = {()=>this.refreshData()}
                            />
                        }
                        {this.state.bankUpdate &&
                            <BankUpdate
                                data = {this.state.editData}
                                bankUpdate = {this.state.bankUpdate}
                                handleCurrentData = {this.handleCurrentData}
                                notifyUpdate = {notifyUpdate}
                                notifyError = {notifyError}
                                refreshData = {()=>this.refreshData()}
                            />
                        }
                    </ModalBody>
                <ToastContainer />
                </Modal>
            </Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        bankList: state.bankList
    }
}

export default connect(mapStateToProps, {
    getAllBankList
})(Bank)