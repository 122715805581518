export const expense = (state = { userRole: "Admin" }, action) => {
  switch (action.type) {

    // LISTING  
    case "GET_EXPENSE_REQUEST": {
      return { ...state, isexpenselistLoading: true }
    }
    case "GET_EXPENSE_SUCCESS": {
      return { ...state, values: action.payload, expenseList: action.payload, isexpenselistLoading: false }
    }
    case "GET_EXPENSE_ERROR": {
      return { ...state, isexpenselistLoading: false, expenseListError: action.payload }
    }

    // ADD
    case "ADD_EXPENSE_REQUEST": {
      return { ...state, isexpenseaddLoading: true }
    }
    case "ADD_EXPENSE_SUCCESS": {
      return { ...state, expenseAdd: action.payload, expenseAddError: '', isexpenseaddLoading: false }
    }
    case "ADD_EXPENSE_ERROR": {
      return { ...state, isexpenseaddLoading: false, expenseAddError: action.payload, expenseAdd: '' }
    }


    default: {
      return state
    }
  }
}
