export const approval = (state = { userRole: "Admin" }, action) => {
    switch (action.type) { 

      // LISTING  
      case "GET_STAFF_REQUEST_LIST_REQUEST": {
        return { ...state, isapprovallistLoading: true }
      }
      case "GET_STAFF_REQUEST_LIST_SUCCESS": {
        return {...state , values: action.payload, approvalList:action.payload, isapprovallistLoading : false }
      }
      case "GET_STAFF_REQUEST_LIST_ERROR" : {
        return {...state, isapprovallistLoading : false, approvalListError :action.payload }
      }


      // UPDATE
      case "UPDATE_STAFF_REQUEST_REQUEST": {
        return { ...state, isapprovalupdateLoading: true }
      }
      case "UPDATE_STAFF_REQUEST_SUCCESS": {
        return {...state , values: action.payload, approvalUpdate:action.payload, isapprovalupdateLoading : false }
      }
      case "UPDATE_STAFF_REQUEST_ERROR" : {
        return {...state, isapprovalupdateLoading : false, approvalUpdateError :action.payload }
      }

      default: {
        return state
      }
    }
  }
  