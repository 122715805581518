export const salary = (state = { userRole: "Admin" }, action) => {
    switch (action.type) { 

      // LISTING  
      case "GET_SALARY_REQUEST": {
        return { ...state, issalarylistLoading: true }
      }
      case "GET_SALARY_SUCCESS": {
        return {...state , values: action.payload, salaryList:action.payload, issalarylistLoading : false }
      }
      case "GET_SALARY_ERROR" : {
        return {...state, issalarylistLoading : false, salaryListError :action.payload }
      }

      // ADD
      case "ADD_SALARY_REQUEST": {
        return { ...state, issalaryaddLoading: true }
      }
      case "ADD_SALARY_SUCCESS": {
        return {...state , values: action.payload, salaryAdd:action.payload, issalaryaddLoading : false }
      }
      case "ADD_SALARY_ERROR" : {
        return {...state, issalaryaddLoading : false, salaryAddError :action.payload }
      }

      // UPDATE
      case "UPDATE_SALARY_REQUEST": {
        return { ...state, issalaryupdateLoading: true }
      }
      case "UPDATE_SALARY_SUCCESS": {
        return {...state , values: action.payload, salaryUpdate:action.payload, issalaryupdateLoading : false }
      }
      case "UPDATE_SALARY_ERROR" : {
        return {...state, issalaryupdateLoading : false, salaryUpdateError :action.payload }
      }

      // DELETE
      case "DELETE_SALARY_REQUEST": {
        return { ...state, issalarydeleteLoading: true }
      }
      case "DELETE_SALARY_SUCCESS": {
        return {...state , values: action.payload, salaryDelete:action.payload, issalarydeleteLoading : false }
      }
      case "DELETE_SALARY_ERROR" : {
        return {...state, issalarydeleteLoading : false, salaryDeleteError :action.payload }
      }

      default: {
        return state
      }
    }
  }
  