export const schedule = (state = { userRole: "Admin" }, action) => {
    switch (action.type) { 

      // LISTING  
      case "GET_SCHEDULE_LIST_REQUEST": {
        return { ...state, isschedulelistLoading: true }
      }
      case "GET_SCHEDULE_LIST_SUCCESS": {
        return {...state , values: action.payload, scheduleList:action.payload, isschedulelistLoading : false }
      }
      case "GET_SCHEDULE_LIST_ERROR" : {
        return {...state, isschedulelistLoading : false, scheduleListError :action.payload }
      }

      // ADD
      case "ADD_SCHEDULE_REQUEST": {
        return { ...state, isscheduleaddLoading: true }
      }
      case "ADD_SCHEDULE_SUCCESS": {
        return {...state , values: action.payload, scheduleAdd:action.payload, isscheduleaddLoading : false }
      }
      case "ADD_SCHEDULE_ERROR" : {
        return {...state, isscheduleaddLoading : false, scheduleAddError :action.payload }
      }

      // UPDATE
      case "UPDATE_SCHEDULE_REQUEST": {
        return { ...state, isscheduleupdateLoading: true }
      }
      case "UPDATE_SCHEDULE_SUCCESS": {
        return {...state , values: action.payload, scheduleUpdate:action.payload, isscheduleupdateLoading : false }
      }
      case "UPDATE_SCHEDULE_ERROR" : {
        return {...state, isscheduleupdateLoading : false, scheduleUpdateError :action.payload }
      }

      // DELETE
    //   case "DELETE_SCHEDULE_REQUEST": {
    //     return { ...state, isscheduledeleteLoading: true }
    //   }
    //   case "DELETE_SCHEDULE_SUCCESS": {
    //     return {...state , values: action.payload, scheduleDelete:action.payload, isscheduledeleteLoading : false }
    //   }
    //   case "DELETE_SCHEDULE_ERROR" : {
    //     return {...state, isscheduledeleteLoading : false, scheduleDeleteError :action.payload }
    //   }

      default: {
        return state
      }
    }
  }
  