export const calendar = (state = { userRole: "Admin" }, action) => {
    switch (action.type) { 

      // LISTING  
      case "GET_ALL_EVENTS_REQUEST": {
        return { ...state, iseventlistLoading: true }
      }
      case "GET_ALL_EVENTS_SUCCESS": {
        return {...state , values: action.payload, 
          allEventList:action.payload, 
          allEventListError: null,
          iseventlistLoading : false }
      }
      case "GET_ALL_EVENTS_ERROR" : {
        return {...state, iseventlistLoading : false,
            allEventListError :action.payload,
           allEventList: null,
          }
      }

      default: {
        return state
      }
    }
  }
  