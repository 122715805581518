import { combineReducers } from "redux"
import calenderReducer from "./calendar/"
import emailReducer from "./email/"
import chatReducer from "./chat/"
import todoReducer from "./todo/"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import dataList from "./data-list/"
import staffList from "./staff"
import workList from  "./work"
import approvalList from "./staffrequest"
import historyList from "./workhistory"
import staffWorkHistoryList from "./staffworkhistory"
import paymentList from "./workpayment"
import calendarList from "./crmcalendar"
import salaryList from "./salary"
import scheduleList from "./schedule"
import messList from "./mess"
import assignedList from './workschedule'
import freelancerList from './freelancer'
import bankList from './bank'
import expenseList from './expense'

const rootReducer = combineReducers({
  calendar: calenderReducer,
  emailApp: emailReducer,
  todoApp: todoReducer,
  chatApp: chatReducer,
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList: dataList,
  staffList: staffList,
  workList: workList,
  approvalList: approvalList,
  historyList: historyList,
  staffWorkHistoryList: staffWorkHistoryList,
  paymentList: paymentList,
  calendarList: calendarList,
  salaryList: salaryList,
  scheduleList: scheduleList,
  messList: messList,
  assignedList: assignedList,
  freelancerList: freelancerList,
  bankList : bankList,
  expenseList : expenseList
})

export default rootReducer
