
import { performRequest } from '../../../services/index';
import { history } from '../../../history';

export const logout = () => {
    localStorage.removeItem("crmuser")
    localStorage.removeItem("crmtoken")
    history.push("/")
}

export const getAllBankList = () => {
    let token = JSON.parse(localStorage.getItem('crmtoken'))
    const headers = {
        "Authorization": `Manager ${token}`
    }
  return dispatch => {
    dispatch({
      type: 'GET_ALL_BANK_LIST_REQUEST'
    })
    return performRequest('get', '/bank', headers)
      .then((response) => {
        console.log(response,'res')
        if (response.status === 200) {
          console.log('enter')
          dispatch({
            type: 'GET_ALL_BANK_LIST_SUCCESS',
            payload: response.data
          })
        }
        if (response.status === 400) {
          dispatch({
            type: 'GET_ALL_BANK_LIST_ERROR',
            payload: response.data
          })
        }
      })
      .catch((error) => {
        console.log(error.response, 'error')
        if(error.message === "Network Error"){
          history.push("/error/500")
        }
        else if(error.response.status === 403){
          logout()
        }
        else{
        dispatch({
          type: 'GET_ALL_BANK_LIST_ERROR',
          payload: error
        })
      }
      })
  }
}

export const getBankDetails = (bankId) => {
    let token = JSON.parse(localStorage.getItem('crmtoken'))
    const headers = {
        "Authorization": `Manager ${token}`
    }
  return dispatch => {
    dispatch({
      type: 'GET_BANK_REQUEST'
    })
    return performRequest('get', `/bank/${bankId}`, headers)
      .then((response) => {
        console.log(response,'res')
        if (response.status === 200) {
          console.log('enter')
          dispatch({
            type: 'GET_BANK_SUCCESS',
            payload: response.data
          })
        }
        if (response.status === 400) {
          dispatch({
            type: 'GET_BANK_ERROR',
            payload: response.data
          })
        }
      })
      .catch((error) => {
        console.log(error.response, 'error')
        if(error.message === "Network Error"){
          history.push("/error/500")
        }
        else if(error.response.status === 403){
          logout()
        }
        else{
        dispatch({
          type: 'GET_BANK_ERROR',
          payload: error
        })
      }
      })
  }
}

export const addBank = (data) => {
  let token = JSON.parse(localStorage.getItem('crmtoken'))
  let postReqObj = {};
  postReqObj.accountName = data.accountName;
  postReqObj.accountNumber = data.accountNumber;
  postReqObj.bankName = data.bankName;

  const headers = {
      "Authorization": `Manager ${token}`
  }
return dispatch => {
  dispatch({
    type: 'ADD_BANK_REQUEST'
  })
  return performRequest('post', '/bank', headers, postReqObj)
    .then((response) => {
      console.log(response,'ADD res')
      if (response.status === 200) {
        dispatch({
          type: 'ADD_BANK_SUCCESS',
          payload: response.data
        })
        // getStaffList(1)
      }
      if (response.status === 400) {
        dispatch({
          type: 'ADD_BANK_ERROR',
          payload: response.data
        })
      }
    })
    .catch((error) => {
      console.log(error,'error')
      if(error.message === "Network Error"){
        history.push("/error/500")
      }
      else if(error.response.status === 403){
        logout()
      }
      else{
      dispatch({
        type: 'ADD_BANK_ERROR',
        payload: error.response.data
      })
    }
    })
}
}

export const updateBank = (data,accountId) => {
  let token = JSON.parse(localStorage.getItem('crmtoken'))
  let postReqObj = {};
  postReqObj.accountId = accountId;
  postReqObj.accountName = data.accountName;
  postReqObj.accountNumber = data.accountNumber;
  postReqObj.amount = data.amount;
  postReqObj.bankName = data.bankName;
  postReqObj.status = data.status;


  const headers = {
      "Authorization": `Manager ${token}`
  }
return dispatch => {
  dispatch({
    type: 'UPDATE_BANK_REQUEST'
  })
  return performRequest('put', `/bank`, headers, postReqObj)
    .then((response) => {
      console.log(response,'update res')
      if (response.status === 200) {
        dispatch({
          type: 'UPDATE_BANK_SUCCESS',
          payload: response
        })
        // getStaffList(1)
      }
      if (response.status === 400) {
        dispatch({
          type: 'UPDATE_BANK_ERROR',
          payload: response.data
        })
      }
    })
    .catch((error) => {
      console.log(error,'error')
      if(error.message === "Network Error"){
        history.push("/error/500")
      }
      else if(error.response.status === 403){
        logout()
      }
      else{
      dispatch({
        type: 'UPDATE_BANK_ERROR',
        payload: error.response.data
      })
    }
    })
}
}





