export const mess = (state = { userRole: "Admin" }, action) => {
    switch (action.type) { 

      // LISTING  
      case "GET_MESS_REQUEST": {
        return { ...state, ismesslistLoading: true }
      }
      case "GET_MESS_SUCCESS": {
        return {...state , values: action.payload, 
          messList:action.payload, 
          ismesslistLoading : false,
          messListError: ''
        }
      }
      case "GET_MESS_ERROR" : {
        return {...state, ismesslistLoading : false, 
          messListError :action.payload,
          messList: ""
         }
      }

      // ADD
      case "ADD_MESS_REQUEST": {
        return { ...state, ismessaddLoading: true }
      }
      case "ADD_MESS_SUCCESS": {
        return {...state , values: action.payload, messAdd:action.payload, ismessaddLoading : false }
      }
      case "ADD_MESS_ERROR" : {
        return {...state, ismessaddLoading : false, messAddError :action.payload }
      }

      // UPDATE
    //   case "UPDATE_MESS_REQUEST": {
    //     return { ...state, ismessupdateLoading: true }
    //   }
    //   case "UPDATE_MESS_SUCCESS": {
    //     return {...state , values: action.payload, messUpdate:action.payload, ismessupdateLoading : false }
    //   }
    //   case "UPDATE_MESS_ERROR" : {
    //     return {...state, ismessupdateLoading : false, messUpdateError :action.payload }
    //   }

      // DELETE
      case "DELETE_MESS_REQUEST": {
        return { ...state, ismessdeleteLoading: true }
      }
      case "DELETE_MESS_SUCCESS": {
        return {...state , values: action.payload, messDelete:action.payload, ismessdeleteLoading : false }
      }
      case "DELETE_MESS_ERROR" : {
        return {...state, ismessdeleteLoading : false, messDeleteError :action.payload }
      }

      default: {
        return state
      }
    }
  }
  