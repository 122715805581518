export const bank = (state = { userRole: "Admin" }, action) => {
    switch (action.type) {
  
      // LISTING  
      case "GET_ALL_BANK_LIST_REQUEST": {
        return { ...state, isallbankistLoading: true }
      }
      case "GET_ALL_BANK_LIST_SUCCESS": {
        return {
          ...state,
          allBankList: action.payload,
          allBankListError: null,
          isallbankistLoading: false
        }
      }
      case "GET_ALL_BANK_LIST_ERROR": {
        return {
          ...state, isallbankistLoading: false,
          allBankListError: action.payload,
          allBankList: null,
        }
      }
  
      case "GET_BANK_REQUEST": {
        return { ...state, isbankLoading: true }
      }
      case "GET_BANK_SUCCESS": {
        return {
          ...state,
          bankData: action.payload,
          bankDataError: null,
          isbankLoading: false
        }
      }
      case "GET_BANK_ERROR": {
        return {
          ...state, isbankLoading: false,
          bankDataError: action.payload,
          bankData: null
        }
      }
  
      // ADD
      case "ADD_BANK_REQUEST": {
        return { ...state, isbankaddLoading: true }
      }
      case "ADD_BANK_SUCCESS": {
        return { ...state, bankAdd: action.payload, isbankaddLoading: false }
      }
      case "ADD_BANK_ERROR": {
        return { ...state, isbankaddLoading: false, bankAddError: action.payload }
      }
  
      // UPDATE
      case "UPDATE_BANK_REQUEST": {
        return { ...state, isbankupdateLoading: true }
      }
      case "UPDATE_BANK_SUCCESS": {
        return { ...state, bankUpdate: action.payload, isbankupdateLoading: false }
      }
      case "UPDATE_BANK_ERROR": {
        return { ...state, isbankupdateLoading: false, bankUpdateError: action.payload }
      }
  
  
      default: {
        return state
      }
    }
  }
  