export const history = (state = { userRole: "Admin" }, action) => {
    switch (action.type) { 

      // LISTING  
      case "GET_STAFF_WORK_HISTORY_LIST_REQUEST": {
        return { ...state, isstaffworkhistorylistLoading: true }
      }
      case "GET_STAFF_WORK_HISTORY_LIST_SUCCESS": {
        return {...state , values: action.payload, historyList:action.payload, isstaffworkhistorylistLoading : false }
      }
      case "GET_STAFF_WORK_HISTORY_LIST_ERROR" : {
        return {...state, isstaffworkhistorylistLoading : false, historyListError :action.payload }
      }


      default: {
        return state
      }
    }
  }
  