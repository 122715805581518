
import { performRequest } from '../../../services/index';
import { history } from '../../../history';

export const logout = () => {
    localStorage.removeItem("crmuser")
    localStorage.removeItem("crmtoken")
    history.push("/")
}

export const getAllWorkList = () => {
    let token = JSON.parse(localStorage.getItem('crmtoken'))
    const headers = {
        "Authorization": `Manager ${token}`
    }
  return dispatch => {
    dispatch({
      type: 'GET_ALL_WORK_LIST_REQUEST'
    })
    return performRequest('get', '/work/get-all-work', headers)
      .then((response) => {
        console.log(response,'res')
        if (response.status === 200) {
          console.log('enter')
          dispatch({
            type: 'GET_ALL_WORK_LIST_SUCCESS',
            payload: response.data
          })
        }
        if (response.status === 400) {
          dispatch({
            type: 'GET_ALL_WORK_LIST_ERROR',
            payload: response.data
          })
        }
      })
      .catch((error) => {
        console.log(error.response, 'error')
        if(error.message === "Network Error"){
          history.push("/error/500")
        }
        else if(error.response.status === 403){
          logout()
        }
        else{
        dispatch({
          type: 'GET_ALL_WORK_LIST_ERROR',
          payload: error
        })
      }
      })
  }
}

export const getWorkPaymentList = (workId) => {
    let token = JSON.parse(localStorage.getItem('crmtoken'))
    const headers = {
        "Authorization": `Manager ${token}`
    }
  return dispatch => {
    dispatch({
      type: 'GET_WORK_PAYMENT_REQUEST'
    })
    return performRequest('get', `/workpayment/${workId}`, headers)
      .then((response) => {
        console.log(response,'res')
        if (response.status === 200) {
          console.log('enter')
          dispatch({
            type: 'GET_WORK_PAYMENT_SUCCESS',
            payload: response.data
          })
        }
        if (response.status === 400) {
          dispatch({
            type: 'GET_WORK_PAYMENT_ERROR',
            payload: response.data
          })
        }
      })
      .catch((error) => {
        console.log(error.response, 'error')
        if(error.message === "Network Error"){
          history.push("/error/500")
        }
        else if(error.response.status === 403){
          logout()
        }
        else{
        dispatch({
          type: 'GET_WORK_PAYMENT_ERROR',
          payload: error
        })
      }
      })
  }
}

export const addPayment = (data) => {
  let token = JSON.parse(localStorage.getItem('crmtoken'))
  let postReqObj = {};
  postReqObj.workId = data.workId;
  postReqObj.remark = data.remark;
  // postReqObj.debit = data.debit;
  postReqObj.credit = data.credit;
  postReqObj.bankId = data.bankId;

  const headers = {
      "Authorization": `Manager ${token}`
  }
return dispatch => {
  dispatch({
    type: 'ADD_PAYMENT_REQUEST'
  })
  return performRequest('post', '/workpayment', headers, postReqObj)
    .then((response) => {
      console.log(response,'ADD res')
      if (response.status === 200) {
        dispatch({
          type: 'ADD_PAYMENT_SUCCESS',
          payload: response.data
        })
        // getStaffList(1)
      }
      if (response.status === 400) {
        dispatch({
          type: 'ADD_PAYMENT_ERROR',
          payload: response.data
        })
      }
    })
    .catch((error) => {
      console.log(error,'error')
      if(error.message === "Network Error"){
        history.push("/error/500")
      }
      else if(error.response.status === 403){
        logout()
      }
      else{
      dispatch({
        type: 'ADD_PAYMENT_ERROR',
        payload: error.response.data
      })
    }
    })
}
}

export const updatePayment = (data,paymentid) => {
  let token = JSON.parse(localStorage.getItem('crmtoken'))
  let postReqObj = {};
  postReqObj.id = paymentid;
  postReqObj.workId = data.workId;
  postReqObj.remark = data.remark;
  // postReqObj.debit = data.debit;
  postReqObj.credit = data.credit;
  postReqObj.bankId = data.bankId;

  const headers = {
      "Authorization": `Manager ${token}`
  }
return dispatch => {
  dispatch({
    type: 'UPDATE_PAYMENT_REQUEST'
  })
  return performRequest('put', `/workpayment`, headers, postReqObj)
    .then((response) => {
      console.log(response,'update res')
      if (response.status === 200) {
        dispatch({
          type: 'UPDATE_PAYMENT_SUCCESS',
          payload: response
        })
        // getStaffList(1)
      }
      if (response.status === 400) {
        dispatch({
          type: 'UPDATE_PAYMENT_ERROR',
          payload: response.data
        })
      }
    })
    .catch((error) => {
      console.log(error,'error')
      if(error.message === "Network Error"){
        history.push("/error/500")
      }
      else if(error.response.status === 403){
        logout()
      }
      else{
      dispatch({
        type: 'UPDATE_PAYMENT_ERROR',
        payload: error.response.data
      })
    }
    })
}
}

// Profit
export const getTotalProfit = (startDate,endDate) => {
  let token = JSON.parse(localStorage.getItem('crmtoken'))
  const headers = {
      "Authorization": `Manager ${token}`
  }
return dispatch => {
  dispatch({
    type: 'GET_TOTAL_PROFIT_REQUEST'
  })
  return performRequest('get', `/workpayment/profit?startDate=${startDate}&endDate=${endDate}`, headers)
    .then((response) => {
      console.log(response,'res')
      if (response.status === 200) {
        console.log('enter')
        dispatch({
          type: 'GET_TOTAL_PROFIT_SUCCESS',
          payload: response.data
        })
      }
      if (response.status === 400) {
        dispatch({
          type: 'GET_TOTAL_PROFIT_ERROR',
          payload: response.data
        })
      }
    })
    .catch((error) => {
      console.log(error.response, 'error')
      if(error.message === "Network Error"){
        history.push("/error/500")
      }
      else if(error.response.status === 403){
        logout()
      }
      else{
      dispatch({
        type: 'GET_TOTAL_PROFIT_ERROR',
        payload: error
      })
    }
    })
}
}



