export const freelancer = (state = { userRole: "Admin" }, action) => {
    switch (action.type) { 

      // LISTING  
      case "GET_FREELANCER_REQUEST": {
        return { ...state, isfreelancerlistLoading: true }
      }
      case "GET_FREELANCER_SUCCESS": {
        return {...state , values: action.payload, freelancerList:action.payload, isfreelancerlistLoading : false }
      }
      case "GET_FREELANCER_ERROR" : {
        return {...state, isfreelancerlistLoading : false, freelancerListError :action.payload }
      }

      // ADD
      case "ADD_FREELANCER_REQUEST": {
        return { ...state, isfreelanceraddLoading: true }
      }
      case "ADD_FREELANCER_SUCCESS": {
        return {...state , values: action.payload, freelancerAdd:action.payload, isfreelanceraddLoading : false }
      }
      case "ADD_FREELANCER_ERROR" : {
        return {...state, isfreelanceraddLoading : false, freelancerAddError :action.payload }
      }


      default: {
        return state
      }
    }
  }
  